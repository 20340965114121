import React from "react";

import { Box, Card, Theme, Typography, useMediaQuery, useTheme } from "@material-ui/core";

import Button from "@remar/shared/dist/components/Button";
import LackOfFundsDiscount from "@remar/shared/dist/components/LackOfFundsDiscount";
import ContentLoader from "@remar/shared/dist/layouts/TableContentLayout/components/ContentLoader";
import { IErrorResponseData } from "@remar/shared/dist/models";

import clsx from "clsx";
import { useAppSelector } from "store";

import { getFullState } from "store/features/Auth/authSlice";

import { CheckSvg, ErrorSvg } from "assets/icons";

import { StyledRemarLogoFullSVG, useStyles } from "./styles";

const SignUpFollowUpScreen = ({
	success,
	error,
	onClick,
	invitationError,
	showTryAgainButton
}: {
	success: boolean;
	error: boolean;
	onClick: () => void;
	invitationError: IErrorResponseData | null;
	showTryAgainButton?: boolean;
}) => {
	const theme = useTheme<Theme>();
	const classes = useStyles();
	const isMobile = useMediaQuery(theme.breakpoints.down("xs"));
	const { invitationDetails } = useAppSelector(getFullState);
	const BoxPadding = isMobile ? 0 : 22;

	const locationName = invitationDetails?.invitedBy.locationName;

	return (
		<Card>
			<Box display="flex" justifyContent="center" p={5} pl={BoxPadding} pr={BoxPadding}>
				<Box display="flex" justifyContent="center" flexDirection="column" alignItems={"center"}>
					<Box display="flex" justifyContent="center" mb={5} mt={4}>
						{success && <CheckSvg width={70} height={70} />}
						{error && <ErrorSvg width={70} height={70} />}
					</Box>
					<Typography
						className={`${classes.signUpTitleFollowUp} ${success && classes.successTitleFollowUp} ${
							error && classes.errorTitleFollowUp
						}`}
					>
						{success && "Successful Onboarding!"}
						{error && ((invitationError && invitationError.title) || "Something Went Wrong")}
					</Typography>
					<Typography className={classes.signUpSubTitleTextFollowUp}>
						{success && `Welcome to ${locationName ?? "ReMar"}!`}{" "}
						{error &&
							((invitationError && invitationError.message) ||
								"We are not able to process your payment. Please try again.")}
					</Typography>
					{success && <ContentLoader height={100} size={"3rem"} />}
					{error && <LackOfFundsDiscount />}
					{showTryAgainButton && (
						<Box display="flex" justifyContent="center" mt={4} width={"100%"}>
							<Button color="primary" variant="filled" onClick={onClick} style={{ width: "90%" }}>
								Try Again
							</Button>
						</Box>
					)}
					{locationName && (
						<>
							<Typography className={clsx(classes.signUpSubTitleTextFollowUp, classes.poweredBy)}>
								Powered by
							</Typography>
							<StyledRemarLogoFullSVG width={63} height={22} />
						</>
					)}
				</Box>
			</Box>
		</Card>
	);
};

export default SignUpFollowUpScreen;
