import React from "react";

import { Box, LinearProgress, List, ListItem, Card as MuiCard, Radio, Typography } from "@material-ui/core";

import { RemarBadge } from "@remar/shared/dist/components/RemarBadge";

import { BadgeInfo } from "@remar/shared/dist/models";
import { format } from "date-fns";
import styled from "styled-components";

import { CheckMarkSVG } from "assets/icons";

const Card = styled(MuiCard)`
	height: 100%;

	.title {
		font-family: var(--openSans);
		font-size: 15px;
		font-weight: 600;
	}

	.card-body {
		margin-top: 20px;

		.card-block {
			display: flex;
			gap: 16px;
			margin-bottom: 24px;

			.status-block {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 6px;

				p {
					font-family: var(--poppins);
					font-size: 18px;
					font-weight: 700;
				}

				.received-on {
					display: flex;
					gap: 8px;

					p {
						font-size: 12px;
						font-family: var(--openSans);
						font-weight: 600;
						color: ${({ theme }) => theme.palette.text.primary};
						opacity: 0.87;

						&:first-child {
							opacity: 1;
							font-weight: normal;
							color: ${({ theme }) => theme.palette.text.secondary};
						}
					}
				}
			}
		}

		ul {
			background: transparent;

			li {
				padding: 14px 0;
				display: flex;
				justify-content: space-between;

				p {
					font-family: var(--openSans);
					font-size: 13px;
					font-weight: 600;
					opacity: 0.87;
					text-align: right;
				}

				div {
					display: flex;
					gap: 16px;
					align-items: center;

					p {
						text-align: left;
						font-weight: normal;
						color: ${({ theme }) => theme.palette.text.secondary};
					}
				}

				&:last-child {
					padding-bottom: 0;
				}
			}
		}
	}
`;

const renderMarker = (metConditions: boolean) =>
	metConditions ? <CheckMarkSVG /> : <Radio checked={false} style={{ padding: 0 }} />;

type RemarReadyBadgeProgressProps = BadgeInfo;
const RemarReadyBadgeProgress = ({
	percentage,
	catPassed,
	timeSpentTraining,
	timeSpentRequired,
	courseCompletionPercentage,
	isCourseComplete,
	receivedOn
}: RemarReadyBadgeProgressProps) => {
	return (
		<Card>
			<Typography className="title">Remar Ready Badge</Typography>
			<Box className="card-body">
				<Box className="card-block">
					<RemarBadge size="large" />
					<Box width="100%">
						<Box className="status-block">
							<Typography>{percentage || 0}%</Typography>
							{receivedOn && (
								<Box className="received-on">
									<Typography>
										Badge received on: <strong>{format(Date.parse(receivedOn as string), "MMMM d, yyyy")}</strong>
									</Typography>
									<Typography>{isCourseComplete}</Typography>
								</Box>
							)}
						</Box>
						<LinearProgress className={"progress"} variant="determinate" value={percentage} />
					</Box>
				</Box>
				<List>
					<ListItem>
						<Box>
							{renderMarker(courseCompletionPercentage >= 100)}
							<Typography>Course completion</Typography>
						</Box>
						<Typography>{Math.round(courseCompletionPercentage) || 0}/100%</Typography>
					</ListItem>
					<ListItem>
						<Box>
							{renderMarker(catPassed)}
							<Typography>Passing score on CAT Exam</Typography>
						</Box>
						<Typography>{catPassed ? "Passed" : "Not Passed"}</Typography>
					</ListItem>
					<ListItem>
						<Box>
							{renderMarker(timeSpentTraining >= timeSpentRequired)}
							<Typography>Time Spent Training in the V2</Typography>
						</Box>
						<Typography>
							{timeSpentTraining || 0}/{timeSpentRequired} hours
						</Typography>
					</ListItem>
				</List>
			</Box>
		</Card>
	);
};

export default RemarReadyBadgeProgress;
