import React, { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Tooltip, useTheme } from "@material-ui/core";
import { Note } from "@material-ui/icons";
import Button from "@remar/shared/dist/components/Button";
import { QuizCalculator } from "@remar/shared/dist/components/QuizCalculator";
import { WritingPad } from "@remar/shared/dist/components/WritingPad";
import { QuestionBankTestModes, QuestionTypes } from "@remar/shared/dist/constants";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { getSanitizedHtmlText } from "@remar/shared/dist/utils/serviceUtils";

import useAnalyticsEventTracker from "hooks/googleAnalytics";

import { Prompt } from "react-router";
import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "store";
import { getFullState, setCSQuestions, setOptionsError } from "store/features/QuestionBank/Test/test.slice";

import { routes } from "core/constants";
import MultipleChoiceQuestion from "modules/Lesson/MultipleChoiceQuestion";

import SingleChoiceQuestion from "modules/Lesson/SingleChoiceQuestion";
import CaseStudyQuestion, { Alert as CaseStudyAlert } from "modules/QuestionBank/Test/Components/CaseStudyQuestion";
import MatrixMultipleChoiceQuestion from "modules/QuestionBank/Test/Components/MatrixMultipleChoiceQuestion";

import BowTieQuestion from "./Components/BowTieQuestion";
import ClozeDropDownQuestion from "./Components/ClozeDropDownQuestion";

import DragAndDropQuestion from "./Components/DragAndDropQuestion";
import DropDownTableQuestion from "./Components/DropDownTableQuestion";
import HighlightTableQuestion from "./Components/HighlightTableQuestion";
import HotspotHighlightQuestion from "./Components/HotspotHighlightQuestion";
import MatrixSingleChoiceQuestion from "./Components/MatrixSingleChoiceQuestion";
import MultipleResponseGroupQuestion from "./Components/MultipleResponseGroupQuestion";
import QuizResultSummaryCard from "./Components/QuizResultSummaryCard";

import TutoredTestResults from "./TutoredTestResults";

import {
	ButtonContainer,
	CalculatorIcon,
	CountdownIcon,
	FeedbackBtn,
	FeedbackIcon,
	NavigationBarLeftContainer,
	NavigationBarRightContainer,
	NotesBtn,
	QuizCalculatorButton,
	QuizCountdown,
	QuizCountdownText,
	QuizLessonContainer,
	QuizLessonLessonNumber,
	QuizLessonNavigationBar,
	QuizLessonTestTitle,
	QuizResult,
	QuizResultButtons,
	QuizResultHeader
} from "../../Lesson/style";
import {
	CustomTestBackground,
	MarkFilledIcon,
	MarkIcon,
	TestQuizButtonsRow,
	TestQuizQuestionContainer,
	TestQuizQuestionContent,
	TestQuizQuestionText,
	TestQuizQuestionTitle
} from "../styles";

const CustomTest = ({
	test,
	key: uniqueIdentifier,
	requiredProps: {
		checkAllSelection,
		csRef,
		ref,
		feedback,
		handleNext,
		handlePrev,
		handleQuestionMarking,
		handleSaveFeedback,
		handleSaveNotes,
		hoursToDisplay,
		isFeedbackAllowed,
		isFinished,
		isQuestionMarked,
		minutesToDisplay,
		moveToTutoredNextQuestion,
		notes,
		onShowResults,
		question,
		questionIndex,
		quizPercentage,
		resultSummary,
		secondsToDisplay,
		setFeedback,
		setIsQuizExit,
		setNotes,
		setShowRetakeTestModal,
		setShowTutoredResult,
		setUserAnswers,
		setVisibleUtility,
		showFeedbackSuccess,
		showTutoredResult,
		userAnswers,
		visibleUtility,
		setCaseStudyCurrentIndex
	}
}) => {
	const dispatch = useAppDispatch();
	const history = useHistory();
	const theme = useTheme<IExtendedTheme>();
	const {
		id: testId,
		name,
		data: { questions, isTimed },
		typeId
	} = test;

	const [isNextDisabled, setIsNextDisabled] = useState(true);
	const { attemptedCSQuestions, handleNextLoading, optionsError } = useAppSelector(getFullState);
	const analytics = useAnalyticsEventTracker("Question Bank");

	const handleSetAnswerOptions = answerOption => {
		setUserAnswers(answerOption);
		optionsError && dispatch(setOptionsError(""));
	};
	const getQuestionTypeValidation = useCallback(
		(id, question, customUserAnswers) => {
			switch (id) {
				case QuestionTypes.ClozeDropDown:
				case QuestionTypes.RationalScoringDropDown:
				case QuestionTypes.DropDownTable:
					setIsNextDisabled(checkAllSelection(question, "answerOptions", customUserAnswers));
					break;
				case QuestionTypes.DragAndDrop:
				case QuestionTypes.RationalScoringDragAndDrop:
				case QuestionTypes.MatrixSingleChoice:
					setIsNextDisabled(checkAllSelection(question, "selectedAnswerOptions", customUserAnswers));
					break;
				case QuestionTypes.MatrixMultipleChoice: {
					const isAllColumnsSelected = question.data.groups.every(group =>
						customUserAnswers.some(answer => answer.groupId === group.id)
					);
					setIsNextDisabled(!isAllColumnsSelected);
					break;
				}
				case QuestionTypes.HotspotHighlight:
					setIsNextDisabled(customUserAnswers.length <= 0);
					break;
				case QuestionTypes.MultipleResponseGroup:
				case QuestionTypes.HighlightTable:
					const isAllSelected = question.data.groups.every(group =>
						customUserAnswers.some(answer => answer.groupId === group.id)
					);
					setIsNextDisabled(!isAllSelected);
					break;
				case QuestionTypes.BowTie:
					setIsNextDisabled(customUserAnswers.length < 5);
					break;
				default:
					setIsNextDisabled(customUserAnswers.length === 0);
					break;
			}
		},
		[checkAllSelection]
	);

	useEffect(() => {
		question.typeId !== QuestionTypes.CaseStudy && getQuestionTypeValidation(question.typeId, question, userAnswers);
	}, [question.typeId, userAnswers, question, getQuestionTypeValidation]);

	const getQuestionType = id => {
		switch (id) {
			case QuestionTypes.MatrixSingleChoice:
				return (
					<MatrixSingleChoiceQuestion
						question={question}
						onChange={handleSetAnswerOptions}
						userAnswers={userAnswers}
						key={uniqueIdentifier}
					/>
				);
			case QuestionTypes.CaseStudy:
				return (
					<CaseStudyQuestion
						question={question}
						key={uniqueIdentifier}
						getCSValidation={getQuestionTypeValidation}
						questionIndex={questionIndex}
						csRef={csRef}
						setCSQuestions={setCSQuestions}
						attemptedCSQuestions={attemptedCSQuestions}
						loading={handleNextLoading}
						setCaseStudyCurrentIndex={setCaseStudyCurrentIndex}
					/>
				);
			case QuestionTypes.MultipleChoiceSN:
			case QuestionTypes.MultipleChoiceSATA:
				return (
					<MultipleChoiceQuestion
						question={question}
						onChange={handleSetAnswerOptions}
						userAnswers={userAnswers}
						key={uniqueIdentifier}
					/>
				);
			case QuestionTypes.MatrixMultipleChoice:
				return (
					<MatrixMultipleChoiceQuestion
						question={question}
						onChange={handleSetAnswerOptions}
						userAnswers={userAnswers}
						key={uniqueIdentifier}
					/>
				);
			case QuestionTypes.ClozeDropDown:
			case QuestionTypes.RationalScoringDropDown:
				return (
					<ClozeDropDownQuestion
						question={question}
						onChange={handleSetAnswerOptions}
						userAnswers={userAnswers}
						key={uniqueIdentifier}
					/>
				);
			case QuestionTypes.SingleChoice:
				return (
					<SingleChoiceQuestion
						question={question}
						onChange={handleSetAnswerOptions}
						userAnswers={userAnswers}
						key={uniqueIdentifier}
					/>
				);
			case QuestionTypes.DropDownTable:
				return (
					<DropDownTableQuestion
						question={question}
						onChange={handleSetAnswerOptions}
						userAnswers={userAnswers}
						key={uniqueIdentifier}
					/>
				);
			case QuestionTypes.DragAndDrop:
			case QuestionTypes.RationalScoringDragAndDrop:
				return (
					<DragAndDropQuestion
						question={question}
						onChange={handleSetAnswerOptions}
						answersRef={ref}
						userAnswers={userAnswers}
						key={question.id}
					/>
				);
			case QuestionTypes.BowTie:
				return (
					<BowTieQuestion
						question={question}
						onChange={handleSetAnswerOptions}
						userAnswers={userAnswers}
						key={question.id}
						answersRef={ref}
					/>
				);
			case QuestionTypes.MultipleResponseGroup:
				return (
					<MultipleResponseGroupQuestion
						question={question}
						onChange={handleSetAnswerOptions}
						userAnswers={userAnswers}
						key={question.id}
					/>
				);
			case QuestionTypes.HotspotHighlight:
				return (
					<HotspotHighlightQuestion
						question={question}
						onChange={handleSetAnswerOptions}
						key={question.id}
						userAnswers={userAnswers}
					/>
				);
			case QuestionTypes.HighlightTable:
				return (
					<HighlightTableQuestion
						question={question}
						onChange={handleSetAnswerOptions}
						key={question.id}
						userAnswers={userAnswers}
					/>
				);

			default:
				return null;
		}
	};

	const isCAT = useMemo(() => test.typeId === QuestionBankTestModes.CAT, [test]);

	const CustomWrapper = useMemo(
		() =>
			question.typeId === QuestionTypes.CaseStudy
				? ({ children }) => (
						<CustomTestBackground>
							<CaseStudyAlert />
							{children}
						</CustomTestBackground>
				  )
				: React.Fragment,
		[question.typeId]
	);

	const ResultHeader = useMemo(() => {
		if (test.typeId === QuestionBankTestModes.CAT) {
			if (test.passed) {
				return (
					<QuizResultHeader style={{ textAlign: "center" }}>
						Congrats! <br /> You have Passed the test
					</QuizResultHeader>
				);
			} else {
				return (
					<QuizResultHeader style={{ textAlign: "center" }}>
						Try Again! <br /> You have Failed the test
					</QuizResultHeader>
				);
			}
		}

		return <QuizResultHeader>Test Completed</QuizResultHeader>;
	}, [test]);

	const handleChange = e => {
		e.preventDefault();
	};

	const promptMessage = useMemo(
		() =>
			test.typeId === QuestionBankTestModes.CAT
				? "Reminder: Time won't be paused once you leave. Are you sure you want to exit now?"
				: "A test is in progress. Are you sure you want to leave?",
		[test.typeId]
	);

	return (
		<QuizLessonContainer>
			<Prompt when={!isFinished} message={promptMessage} />
			{!isFinished ? (
				<>
					<QuizLessonTestTitle>{name}</QuizLessonTestTitle>
					<QuizLessonNavigationBar>
						<NavigationBarLeftContainer>
							<NotesBtn onClick={() => setVisibleUtility("notes")}>
								<Note style={{ width: "20px", height: "20px", fill: "#b6e0fe" }} />
								Notes
							</NotesBtn>
							<QuizCalculatorButton onClick={() => setVisibleUtility("calculator")}>
								<CalculatorIcon /> Calculator
							</QuizCalculatorButton>
							<FeedbackBtn onClick={() => setVisibleUtility("feedback")} disabled={!isFeedbackAllowed}>
								<Tooltip title="Feedback was already sent" disableHoverListener={!isFeedbackAllowed}>
									<>
										<FeedbackIcon /> Feedback
									</>
								</Tooltip>
							</FeedbackBtn>
							<QuizCalculatorButton onClick={() => handleQuestionMarking()}>
								<Tooltip title="Click to unmark" disableHoverListener={isQuestionMarked}>
									<Box display="flex">
										{isQuestionMarked ? <MarkFilledIcon /> : <MarkIcon />} {isQuestionMarked ? "Marked" : "Mark"}
									</Box>
								</Tooltip>
							</QuizCalculatorButton>
						</NavigationBarLeftContainer>
						<NavigationBarRightContainer>
							<QuizLessonLessonNumber>
								{questionIndex + 1}
								{!isCAT && <span> / {questions.length}</span>}
							</QuizLessonLessonNumber>

							{isTimed && (
								<QuizCountdown>
									<CountdownIcon />
									<QuizCountdownText>Time Left</QuizCountdownText> {hoursToDisplay}:
									{minutesToDisplay.toString().length < 2
										? String(minutesToDisplay).padStart(2, "0")
										: minutesToDisplay}
									:{String(secondsToDisplay).padStart(2, "0")}
								</QuizCountdown>
							)}
						</NavigationBarRightContainer>
					</QuizLessonNavigationBar>
					<TestQuizQuestionContainer>
						<TestQuizQuestionContent
							showTutoredResult={showTutoredResult}
							noPadding={question.typeId === QuestionTypes.CaseStudy}
							onCut={handleChange}
							onCopy={handleChange}
							onPaste={handleChange}
							onSelect={handleChange}
							onContextMenu={handleChange}
						>
							<CustomWrapper>
								<TestQuizQuestionTitle
									pl={question.typeId != QuestionTypes.CaseStudy}
									showTutoredResult={showTutoredResult}
								>
									Question {questionIndex + 1} {question.typeId === QuestionTypes.CaseStudy && "- Case Study"}
								</TestQuizQuestionTitle>
								<TestQuizQuestionText
									pl={"14px"}
									color={theme.palette.colors.basic[600]}
									dangerouslySetInnerHTML={{ __html: question?.description }}
								/>
								<TestQuizQuestionText pl={"14px"}>
									Question: {getSanitizedHtmlText(question?.text)}
								</TestQuizQuestionText>
								<Box my={1}>{!showTutoredResult && question && getQuestionType(question.typeId)}</Box>
								{showTutoredResult && <TutoredTestResults question={question} />}
							</CustomWrapper>
						</TestQuizQuestionContent>
						{visibleUtility === "calculator" && (
							<QuizCalculator onClose={() => setVisibleUtility(null)} backgroundColor={"#eceff4"} />
						)}
						{visibleUtility === "notes" && (
							<WritingPad
								title="Notes"
								placeholder="Write Your Notes Here..."
								text={notes}
								onClose={() => setVisibleUtility(null)}
								onTextChange={setNotes}
								onSave={handleSaveNotes}
								saveOnBlur={true}
								backgroundColor={"#eceff4"}
							/>
						)}
						{visibleUtility === "feedback" && isFeedbackAllowed && (
							<WritingPad
								title="Feedback"
								placeholder="Write Your Feedback Here..."
								text={feedback}
								onClose={() => setVisibleUtility(null)}
								onTextChange={setFeedback}
								onSave={() => handleSaveFeedback()}
								disabled={showFeedbackSuccess}
								buttonText="Send"
								backgroundColor={"#eceff4"}
							/>
						)}
					</TestQuizQuestionContainer>
					<TestQuizButtonsRow>
						<Box display={"flex"} flex={1}>
							<Button variant="filled" color={"basic"} onClick={() => setIsQuizExit(true)}>
								Exit
							</Button>
						</Box>
						{questionIndex > 0 && !isCAT && !showTutoredResult && test.typeId !== QuestionBankTestModes.Tutor && (
							<Button variant="light" color={"control"} onClick={handlePrev}>
								Previous Question
							</Button>
						)}
						{typeId === QuestionBankTestModes.Tutor && !showTutoredResult ? (
							<Button
								variant="filled"
								color="control"
								onClick={e => {
									e.stopPropagation();
									handleNext(() => {
										setShowTutoredResult(true);
									});
								}}
								disabled={isNextDisabled}
							>
								Check Answers
							</Button>
						) : (
							<Button
								variant="filled"
								color="control"
								onClick={() => {
									if (typeId !== QuestionBankTestModes.Tutor) {
										return handleNext();
									} else {
										moveToTutoredNextQuestion();
										return setShowTutoredResult(false);
									}
								}}
								loading={handleNextLoading}
								disabled={
									handleNextLoading || !!optionsError || (typeId !== QuestionBankTestModes.Tutor && isNextDisabled)
								}
							>
								{questionIndex === questions.length - 1 && !isCAT ? "Finish" : "Next Question"}
							</Button>
						)}
					</TestQuizButtonsRow>
				</>
			) : (
				<QuizResult>
					{ResultHeader}
					<QuizResultSummaryCard resultSummary={resultSummary} test={test} quizPercentage={quizPercentage} />
					<QuizResultButtons>
						<ButtonContainer>
							<Button variant="outlined" color="basic" onClick={() => history.push(`${routes.questionBank.getPath()}`)}>
								Go To Homepage
							</Button>{" "}
							<Button
								variant="ghost"
								color="primary"
								onClick={() => history.push(`${routes.questionBank.getPath()}/test/${testId}/history`)}
							>
								See Test History
							</Button>
							{typeId !== QuestionBankTestModes.CAT && (
								<Button
									variant="outlined"
									color="basic"
									onClick={() => {
										setShowRetakeTestModal(true);
									}}
								>
									Retake test
								</Button>
							)}
							<Button
								variant="filled"
								color="primary"
								onClick={() => {
									analytics({ eventName: "clicked_view_test_results", eventIdentifier: test.id });
									onShowResults();
								}}
							>
								View Test Results
							</Button>
						</ButtonContainer>
					</QuizResultButtons>
				</QuizResult>
			)}
		</QuizLessonContainer>
	);
};

export default CustomTest;
