import React, { FC, useMemo } from "react";

import { Box, Typography, useTheme } from "@material-ui/core";

import { Add, Remove } from "@material-ui/icons";

import Coupon from "@remar/shared/dist/components/Coupon";
import { AddonsIntegrationIds } from "@remar/shared/dist/constants";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";
import { formatUSD } from "@remar/shared/dist/utils/formatUSD";
import { getDiscount } from "@remar/shared/dist/utils/getDiscount";

import { RootState, useAppDispatch, useAppSelector } from "store";

import {
	addSubscriptionIntegrations,
	additionSubscriptionIntegrations,
	removeSubscriptionIntegrations,
	validateCoupon
} from "store/features/Auth/authSlice";

import { BookLabel, GuestPaidShippingContainer, useStyles } from "./styles";
import { getBookType } from "./utils";

interface Props {
	setChangeBookModal?: (_) => void;
	bookId?: number;
	lastStep: boolean;
}

const GuestSummary: FC<Props> = ({ setChangeBookModal, lastStep }) => {
	const classes = useStyles();
	const theme = useTheme<IExtendedTheme>();
	const {
		selectedShippingPlan,
		externalIntegrations,
		subExternalIntegrations,
		userSubscriptionTypeId,
		selectedCountryId,
		isValidatingCoupon,
		validatedCoupon,
		invalidCoupon,
		discountScope,
		multipleShippingPlan
	}: RootState["auth"] = useAppSelector((store: RootState) => store.auth);
	const dispatch = useAppDispatch();
	const appDispatch = useAppDispatch();

	const shippingPlan = useMemo(() => {
		return (subExternalIntegrations || []).length > 1 ? multipleShippingPlan : selectedShippingPlan;
	}, [subExternalIntegrations, selectedShippingPlan]);

	const bookPriceSum = subExternalIntegrations?.reduce(function (lastSum, currentItem) {
		return lastSum + (currentItem?.data["price"] * (currentItem?.quantity as number) || 0);
	}, 0);

	const getBookPriceTotal = (booksPrice, shippingPlan) => {
		let total;
		if (booksPrice && shippingPlan?.data["price"]) {
			total = booksPrice + shippingPlan?.data["price"];
			total = formatUSD(total - (validatedCoupon?.discountedAmount || 0));
			return total;
		} else {
			return booksPrice ? formatUSD(booksPrice - (validatedCoupon?.discountedAmount || 0)) : 0;
		}
	};

	const handleApplyCoupon = (coupon: string) => {
		const books = subExternalIntegrations.map(addon => ({ id: addon.id, quantity: addon.quantity || 1 })) || [];
		dispatch(
			validateCoupon({
				couponCode: coupon,
				userSubscriptionTypeId: userSubscriptionTypeId,
				books,
				countryId: selectedCountryId
			})
		);
	};

	return (
		<Box className={`${classes.summaryRoot} ${classes.customFlex}`}>
			<Box>
				<Box mb={4}>
					<Typography className={classes.title}>You Might Also Like</Typography>
					{externalIntegrations
						?.filter(item => !item.parentId)
						?.map(addon => {
							if (!addon) {
								return null;
							}
							const digitalAsset = (addon.counterparts || []).find(
								item => item.integrationId === AddonsIntegrationIds.DigitalAsset
							);
							return (
								<Box
									key={addon.id}
									display="flex"
									justifyContent="space-between"
									alignItems="center"
									mt={2}
									bgcolor="#393e4b"
									p={1}
									pr={2}
									borderRadius={5}
								>
									<Box display="flex" alignItems="center">
										<Box borderRadius={5} overflow="hidden" width={40} height={55}>
											<img className={classes.bookImg} src={addon?.mainImageUrl} />
										</Box>
										<Box ml={1} display="flex" flexDirection={"column"} alignItems={"flex-start"}>
											<Typography className={classes.summaryText}>{addon?.data["name"] as string}</Typography>
											<Typography>{formatUSD(addon?.data["price"] || 0)}</Typography>
											<BookLabel $isPhysical={!Boolean(digitalAsset)}>
												{getBookType(
													digitalAsset ? AddonsIntegrationIds.DigitalAsset : AddonsIntegrationIds.PhysicalAsset
												)}
											</BookLabel>
										</Box>
									</Box>
									<Box>
										<Box
											bgcolor={"#c0c9e5"}
											p={0.5}
											display="flex"
											borderRadius={5}
											justifyContent="center"
											alignItems="center"
											className={classes.cursorPointer}
											onClick={() => {
												appDispatch(additionSubscriptionIntegrations(addon.id));
											}}
										>
											<Add className={classes.addBookCard} />
										</Box>
									</Box>
								</Box>
							);
						})}
				</Box>

				<Box>
					<Typography className={classes.title}>Order Summary</Typography>

					{subExternalIntegrations?.map((addon, i) => (
						<>
							<Box
								display="flex"
								alignItems="center"
								key={addon?.data["name"] as string}
								justifyContent="space-between"
								mt={2}
								p={1}
								pr={2}
							>
								<Box display="flex" width={"100%"} alignItems="center">
									<Box borderRadius={5} overflow="hidden" width={40} height={55}>
										<img className={classes.bookImg} src={addon?.mainImageUrl} />
									</Box>
									<Box ml={1} width={"calc(100% - 40px)"} flexDirection="column" display="flex">
										<Box alignItems="center" display="flex">
											<Typography className={classes.summaryText}>{addon?.data["name"] as string}&nbsp;</Typography>
											{subExternalIntegrations.length === 1 && (
												<span className={classes.changeBook} onClick={() => setChangeBookModal!(true)}>
													{"(Change)"}
												</span>
											)}

											{subExternalIntegrations.length > 1 && (
												<span
													className={classes.removeBook}
													onClick={() => dispatch(removeSubscriptionIntegrations(addon.id))}
												>
													{"(Remove)"}
												</span>
											)}
										</Box>
										<Box width={"100%"} display="flex" justifyContent="space-between" alignItems="center">
											<Box display="flex">
												<Typography>{formatUSD(addon?.data["price"] || 0)}</Typography>
											</Box>

											<Box display="flex" alignItems="center">
												<Box
													bgcolor={"#393e4b"}
													display="flex"
													padding={0.3}
													borderRadius={5}
													justifyContent="center"
													alignItems="center"
													style={{
														cursor: (addon?.quantity as number) > 1 ? "pointer" : "not-allowed",
														...((addon?.quantity as number) === 1 && { pointerEvents: "none" })
													}}
													onClick={() => {
														appDispatch(
															addSubscriptionIntegrations({
																id: addon.id,
																index: i,
																quantity: (addon?.quantity as number) - 1
															})
														);
													}}
												>
													<Remove
														className={classes.removeBookIcon}
														style={{
															color: (addon?.quantity as number) > 1 ? "#eceff4" : "#586179"
														}}
													/>
												</Box>
												<Box className={classes.quantityClass}>{addon?.quantity as number}</Box>
												<Box
													bgcolor={"#393e4b"}
													display="flex"
													padding={0.3}
													borderRadius={5}
													justifyContent="center"
													alignItems="center"
													className={classes.cursorPointer}
													onClick={() => {
														appDispatch(
															addSubscriptionIntegrations({
																id: addon.id,
																index: i,
																quantity: (addon?.quantity as number) + 1
															})
														);
													}}
												>
													<Add className={classes.addBook} />
												</Box>
											</Box>
										</Box>
									</Box>
								</Box>
							</Box>
						</>
					))}
					<Box mt={1}>
						{discountScope?.books && !!validatedCoupon?.discountedAmount && (
							<Typography style={{ color: theme.palette.text.secondary, fontSize: "13px" }} align="right">
								Books Discount - {formatUSD(getDiscount(validatedCoupon, bookPriceSum))}
							</Typography>
						)}
					</Box>
				</Box>
			</Box>

			<Box>
				{shippingPlan && (
					<GuestPaidShippingContainer>
						<Typography className={classes.summaryText} align="right">
							{shippingPlan?.name}
						</Typography>
						<Typography className={classes.summaryCostSmall} align="right">
							{formatUSD(shippingPlan?.data["price"] || 0)}{" "}
						</Typography>
						{discountScope?.shipping && !!validatedCoupon?.discountedAmount && (
							<Typography style={{ color: theme.palette.text.secondary, fontSize: "13px" }} align="right">
								Shipping Discount - {formatUSD(validatedCoupon.discountedAmount || 0)}
							</Typography>
						)}
					</GuestPaidShippingContainer>
				)}
				{lastStep && (
					<Coupon
						onApply={handleApplyCoupon}
						invalidCoupon={invalidCoupon}
						isApplying={isValidatingCoupon}
						validatedCoupon={validatedCoupon}
					/>
				)}
				<Box>
					<Typography className={classes.summaryTextLight} align="right">
						Your total
					</Typography>
					<Typography className={classes.summaryCostBig} align="right">
						{getBookPriceTotal(bookPriceSum, shippingPlan) ?? "N/A"}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
};

export default GuestSummary;
