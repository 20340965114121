export const getPercentageLevel = (quizPercentage: number): string => {
	const percentage = Math.round(quizPercentage);
	return percentage <= 49
		? "Low"
		: percentage >= 50 && percentage <= 60
		? "Fair"
		: percentage >= 61 && percentage <= 70
		? "Good"
		: percentage >= 71 && percentage <= 100
		? "Excellent"
		: "";
};
