import React, { FC, useCallback, useEffect, useMemo, useState } from "react";

import { Box } from "@material-ui/core";
import { ThumbDown, ThumbUp } from "@material-ui/icons";

import { format } from "date-fns";
import isEmpty from "lodash/isEmpty";

import CommentDeleteModal from "./CommentDeleteModal";
import PostComment from "./PostComment";
import {
	CommentContainer,
	CommentInfoWrapper,
	CommentName,
	CommentReply,
	TypeCommentBox as CommentRightContainer,
	CommentShowMore,
	CommentText,
	CommentThumbCount,
	CommentTime,
	DeletedCommentContainer,
	PostCommentBox,
	ReplyContainer,
	StyledChip,
	ViewReplies,
	useStyles
} from "./style";

import IconCommentExclamation from "../../assets/icons/icon-comment-exclamation.svg";
import IconPin from "../../assets/icons/thumbtack.svg";

import { ADMIN_USER_ID, KK_MM_AA, UserTypes } from "../../constants";
import { Badge, LessonComment, User, UserBadgeTypeEnum } from "../../models";
import { getFormattedDate } from "../../utils/getFormattedDate";
import { ActionMenu, CustomMenuItem } from "../ActionMenu";
import { Avatar } from "../Avatar";
import { RemarBadge } from "../RemarBadge";

interface Comment {
	user: (User & { badges: Array<Badge> }) | null;
	comment: LessonComment;
	isAdminMode?: boolean;
	lessonId?: number;
	padding?: number;
	isReply?: boolean;
	loggedInUserId: number;
	onDelete: (commentId: number) => void;
	pinComment: (commentId: number, isPinned: boolean) => void;
	onVoteDelete: (
		commentId: number,
		voteId: number | undefined,
		isLike: boolean | undefined,
		isTopLevel: boolean
	) => void;
	onVote: (commentId: number | undefined, isLike: boolean) => void;
	onMockDelete: (commentId: number | undefined, parentId: number | undefined, text: string) => void;
	onCreate: (text: string, lessonId: number, parentId?: number) => void;
	onEdit: (text: string, lessonId: number, parentId?: number, commentId?: number) => void;
}

const Comment: FC<Comment> = ({
	user,
	comment,
	onDelete,
	onVoteDelete,
	pinComment,
	onVote,
	lessonId = 0,
	padding = 0,
	isReply,
	loggedInUserId,
	onEdit,
	onCreate,
	onMockDelete,
	isAdminMode = false
}) => {
	const fullName = [user?.firstName, user?.lastName].join(" ");
	const userVote = comment?.votes?.find(({ userId }) => userId === loggedInUserId);
	const classes = useStyles({
		...(!isEmpty(userVote) && {
			isLiked: userVote?.isLike,
			isDisliked: !userVote?.isLike
		}),
		totalLike: comment.totalLike,
		totalDislike: comment.totalDislike
	});
	const [readMore, setReadMore] = useState(false);
	const [characterCount, setCharacterCount] = useState(0);
	const [showReplyPostComment, setShowReplyPostComment] = useState(false);
	const [editMode, setEditMode] = useState(false);
	const [commentDeleted, setCommentDeleted] = useState(false);
	const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);

	const measuredRef = useCallback(node => {
		if (node !== null) {
			setCharacterCount(node.textContent.length);
		}
	}, []);

	useEffect(() => {
		characterCount < 160 ? setReadMore(true) : setReadMore(false);
	}, [characterCount]);

	const isOwnComment = useMemo(() => loggedInUserId === user?.id || isAdminMode, [loggedInUserId, user?.id]);

	const isAdmin = useMemo(
		() => user?.id === ADMIN_USER_ID || user?.assignedUserTypes?.some(({ id }) => id === UserTypes.Admin) || false,
		[user?.assignedUserTypes, user?.id]
	);

	const menuItems: Array<CustomMenuItem> = useMemo(
		() => [
			{
				label: "Edit",
				onClick: () => setEditMode(true),
				disabled: false,
				visible: isOwnComment
			},
			{
				label: "Delete",
				onClick: () => setConfirmDeleteModal(true),
				disabled: false,
				visible: isOwnComment
			},
			{
				label: `${comment.isPinned ? "Unpin" : "Pin"} Comment`,
				onClick: () => pinComment(comment.id, !comment.isPinned),
				disabled: false,
				visible: isAdminMode
			}
			// {
			// 	// Feature request to add users ability report unwanted comments
			// 	label: "Report",
			// 	onClick: () => {},
			// 	disabled: false,
			// 	visible: false
			// 	// visible: !isAdmin
			// }
		],
		[isOwnComment, comment.isPinned, comment.id, isAdmin, pinComment]
	);

	const showBadge = useMemo(() => !!user?.badges?.some(b => b.typeId === UserBadgeTypeEnum.ReMarReadyBadge), [user]);

	return (
		<>
			{!commentDeleted ? (
				<>
					<CommentContainer padding={padding}>
						<Avatar variant="rounded" fullName={fullName} profileImageUrl={user?.profileImageUrl as string} />
						<CommentRightContainer display="block">
							<CommentInfoWrapper>
								<CommentName>
									{isAdmin ? "ReMar Support" : fullName}
									{showBadge && <RemarBadge size="small" />}
								</CommentName>
								{isAdmin && <StyledChip label="Admin" color="primary" size="small" />}
								<CommentTime>
									{getFormattedDate(new Date(comment.createdAt as string))} at{" "}
									{format(new Date(comment.createdAt as string), KK_MM_AA)}{" "}
								</CommentTime>
								{comment.isPinned && (
									<Box display="flex" alignItems="center" gridGap={12}>
										<CommentName style={{ fontSize: 12 }}>Pinned</CommentName>
										<img alt="pinned icon" width={16} src={IconPin} />
									</Box>
								)}
							</CommentInfoWrapper>
							{editMode ? (
								<Box display="flex" flex={1}>
									<PostComment
										parentId={!isReply ? comment!.parentId : undefined}
										edit
										lessonId={+lessonId}
										defaultValue={comment.text}
										commentId={comment.id}
										onEditClose={() => setEditMode(false)}
										onEdit={onEdit}
										onCreate={onCreate}
										onMockDelete={onMockDelete}
									/>
								</Box>
							) : (
								<CommentText ref={measuredRef}>
									{readMore ? comment.text : `${comment.text.substring(0, 160)}`}
									{!readMore && "..."}
									{!readMore && <CommentShowMore onClick={() => setReadMore(!readMore)}> Read more</CommentShowMore>}
								</CommentText>
							)}

							<Box display="flex" alignItems={"center"} mt={2} gridGap={16}>
								{isReply && (
									<CommentReply
										showReplyPostComment={showReplyPostComment}
										onClick={() => setShowReplyPostComment(true)}
									>
										Reply
									</CommentReply>
								)}
								<Box display="flex" alignItems={"center"} gridGap={4}>
									<CommentThumbCount>{comment.totalLike}</CommentThumbCount>
									<ThumbUp
										onClick={() => {
											if (userVote?.isLike) {
												onVoteDelete(comment.id, userVote?.id, userVote?.isLike, comment.isTopLevel);
											} else {
												onVote(comment.id, true);
											}
										}}
										className={classes.commentThumbUp}
									/>
								</Box>
								<Box display="flex" alignItems={"center"} gridGap={4}>
									<CommentThumbCount>{comment.totalDislike}</CommentThumbCount>
									<ThumbDown
										onClick={() => {
											if (userVote && !userVote?.isLike) {
												onVoteDelete(comment.id, userVote?.id, userVote?.isLike, comment.isTopLevel);
											} else {
												onVote(comment.id, false);
											}
										}}
										className={classes.commentThumbDown}
									/>
								</Box>
							</Box>
						</CommentRightContainer>
						<Box>
							<ActionMenu customMenuItems={menuItems} />
						</Box>
					</CommentContainer>
					{isReply && !showReplyPostComment && comment?.replies && comment.replies.length !== 0 && (
						<ViewReplies onClick={() => setShowReplyPostComment(true)}>
							View {comment.replies.length} {comment.replies.length > 1 ? "replies" : "reply"}
						</ViewReplies>
					)}
					{showReplyPostComment && (
						<ReplyContainer>
							<Box display="flex" width="100%" gridGap={12} alignItems="center" padding="16px 0">
								<Avatar fullName={fullName} variant="rounded" profileImageUrl={user?.profileImageUrl as string} />
								<PostCommentBox>
									<PostComment
										lessonId={+lessonId}
										parentId={comment.id}
										placeholder={`Reply to ${fullName}...`}
										onEdit={onEdit}
										onCreate={onCreate}
										onMockDelete={onMockDelete}
									/>
								</PostCommentBox>
							</Box>
							{comment?.replies &&
								comment.replies.length !== 0 &&
								comment.replies.map(reply => (
									<Comment
										loggedInUserId={loggedInUserId}
										key={reply.id}
										user={reply.userId === user?.id ? user : reply.user}
										comment={reply}
										isReply={false}
										lessonId={+lessonId}
										onVote={onVote}
										onVoteDelete={onVoteDelete}
										onDelete={onDelete}
										pinComment={pinComment}
										onEdit={onEdit}
										isAdminMode={isAdminMode}
										onCreate={onCreate}
										onMockDelete={onMockDelete}
									/>
								))}
						</ReplyContainer>
					)}
				</>
			) : (
				<DeletedCommentContainer padding={padding}>
					<img alt="comment" src={IconCommentExclamation} className={classes.iconCommentExclamation} width={32} />
					<Box width={"80%"} alignSelf={"center"}>
						{!comment.parentId ? (
							<CommentText>This comment was deleted</CommentText>
						) : (
							<CommentText>This reply was deleted</CommentText>
						)}
					</Box>
				</DeletedCommentContainer>
			)}
			<CommentDeleteModal
				text={"Are you sure you want to delete this comment ?"}
				modalOpen={confirmDeleteModal}
				setModalOpen={setConfirmDeleteModal}
				onConfirm={() => {
					onDelete(comment.id);
					setCommentDeleted(true);
					setConfirmDeleteModal(false);
				}}
			/>
		</>
	);
};

export default Comment;
