import React from "react";

import { Box, useTheme } from "@material-ui/core";

import { ReactComponent as CheckMarkSvg } from "@remar/shared/dist/assets/icons/icon-checkmark.svg";
import { ReactComponent as CrossSvg } from "@remar/shared/dist/assets/icons/icon-cross.svg";

import { QuestionBankTestModes } from "@remar/shared/dist/constants";
import { IExtendedTheme } from "@remar/shared/dist/theme/default";

import { QuizPercentage, QuizResultSummary, QuizResultSummaryRow } from "../../../Lesson/style";
import CircularProgressBar from "../../Charts/CircularProgress";
import {
	CorrectAnswersFilledIcon,
	PartiallyCorrectAnswersIcon,
	StyledChartIcon,
	StyledQuestionIcon,
	TimeIcon,
	WrongAnswersIcon
} from "../../styles";
import { getPercentageLevel } from "../../utils";

const QuizResultSummaryCard = ({ test, resultSummary, quizPercentage }) => {
	const theme = useTheme<IExtendedTheme>();
	const getTimeSpent = () => {
		// If the time spent is greater than 1 hour, it will use '11' as the starting substring. This signifies that it displays the hours, minutes, and seconds..
		// Otherwise it will only show minutes and seconds
		const subStringStart = test.timeSpent >= 3600 ? 11 : 14;
		return new Date(test.timeSpent * 1000).toISOString().substring(subStringStart, 19);
	};
	const isCATPassed = test.passed;

	return test.typeId === QuestionBankTestModes.CAT ? (
		<QuizResultSummary>
			<Box display="flex" justifyContent="center" position={"relative"} width="150px">
				<Box>
					<CircularProgressBar
						strokeWidth={4}
						sqSize={150}
						percentage={100}
						hideIndicatorCircle
						stroke={isCATPassed ? theme.palette.colors.success[500] : theme.palette.colors.danger[500]}
					/>
				</Box>
				<QuizPercentage test={true} position="absolute" width="100px" top={"30px"} left={"27px"}>
					<Box mb={1}>{isCATPassed ? <CheckMarkSvg /> : <CrossSvg />}</Box>
					<Box textAlign={"center"}>{isCATPassed ? "Above Passing Standard" : "Below Passing Standard"}</Box>
				</QuizPercentage>
			</Box>
			<Box display="flex" alignItems="center" flexDirection="column" justifyContent="center">
				<QuizResultSummaryRow>
					<TimeIcon /> Time spent: {getTimeSpent()}
				</QuizResultSummaryRow>
				<QuizResultSummaryRow mt="true">
					<StyledQuestionIcon /> Attempted {test.data.questions.length - 1} questions
				</QuizResultSummaryRow>
				<QuizResultSummaryRow mt="true">
					<StyledChartIcon /> {isCATPassed ? "Above Passing Standard" : "Below Passing Standard"}
				</QuizResultSummaryRow>
			</Box>
		</QuizResultSummary>
	) : (
		<QuizResultSummary>
			<Box display="flex" justifyContent="center" position={"relative"} width="150px">
				<Box>
					<CircularProgressBar strokeWidth={4} sqSize={150} percentage={Math.round(quizPercentage)} />
				</Box>
				<QuizPercentage test={true} position="absolute" width="100px" top={"30px"} left={"27px"}>
					<Box>You scored:</Box>
					<span> {Math.round(quizPercentage)}% </span>
					<Box>{getPercentageLevel(quizPercentage)}</Box>
				</QuizPercentage>
			</Box>
			<Box display="flex" alignItems="center" flexDirection="column" justifyContent="center">
				<QuizResultSummaryRow>
					<TimeIcon /> Time spent: {getTimeSpent()}
				</QuizResultSummaryRow>
				<QuizResultSummaryRow mt="true">
					<CorrectAnswersFilledIcon />{" "}
					{`${resultSummary.correct} ${resultSummary.correct === 1 ? "question" : "questions"} correct`}
				</QuizResultSummaryRow>
				<QuizResultSummaryRow mt="true">
					<PartiallyCorrectAnswersIcon />{" "}
					{`${resultSummary.partiallyCorrect} ${
						resultSummary.partiallyCorrect === 1 ? "question" : "questions"
					} partially correct`}
				</QuizResultSummaryRow>
				<QuizResultSummaryRow mt="true">
					<WrongAnswersIcon />{" "}
					{`${resultSummary.incorrect} ${resultSummary.incorrect === 1 ? "question" : "questions"} incorrect`}
				</QuizResultSummaryRow>
			</Box>
		</QuizResultSummary>
	);
};

export default QuizResultSummaryCard;
